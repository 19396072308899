<template>
    <span class="CS-calculation" id="CS-calculation">
     <BaseCard v-if="calculationInterface && calculationInterface.variables && Object.keys(calculationInterface.variables).length" backgroundColor="#ffffff" padding="25px" class="main" overflow="visible">
        <div v-for="(variable, varName) in calculationInterface.variables" class="variable" :key="`var-${varName}`">
            <div class="variable-label">
                <span class="latex-title" v-html="checkKatexValue(variable.latex_title)"></span>
                <span class="latex-unit" v-html="checkKatexValue(variable.latex_unit)"></span>
                <span class="title" v-if="variable.var_title && variable.var_title[current_language]">
                    {{ variable.var_title[current_language] }}
                </span>
            </div>
            <div class="variable-input-container">
                <FormulateInput
                    v-if="variable.kind == 'user_input'"
                    type="text"
                    :value="variable.value"
                    :validation="`required|validateVariableValue`"
                    :validation-rules="{validateVariableValue: ({value}) => validateVariableValue(variable, value, varName)}"
                    :validation-messages="{
                        required: 'Required',
                        validateVariableValue:
                            variable.validation ||
                            variable.inputRuleText[current_language] ||
                            variable.inputRule[current_language]
                    }"
                    error-behavior="blur"
                    @input="setInputValue( $event, varName )"
                />
                <CSTable
                    v-else-if="variable.kind == 'table_entry' && !variable.dropdownData"
                    :table-id="variable.value.table"
                    :cell-selectable="true"
                    @cell-selected="setTableValue( $event, varName )"
                    :export-id="getExportId(variable)"
                    :parameter="variable.value.parameter"
                />
                <Dropdown
                    v-else-if="variable.kind == 'table_entry' && variable.dropdownData"    
                    class="variable-select"
                    :dropdown-list="variable.dropdownData"
                    :dropdown-button-text="variable.selectedType.toString()"
                    :empty-lablel="labels.no_selectable_value"
                />
                <Tooltip :text="getHelpText(variable)"/>
            </div>
        </div>

        <h6 v-if="executionResult.length" class="excecution-title">
            {{ labels.calculation_results }}
        </h6>

        <div v-for="(excecutionResult, index) in executionResult" class="execution-result" :key="`exec-${index}`">
            <div v-for="(execVariable, varName) in excecutionResult" class="variable" :key="`var-${varName}`">
                <div class="variable-label">
                    <span class="latex-title" v-html="checkKatexValue(execVariable.variable.latex_title)"></span>
                    <span class="latex-unit" v-html="checkKatexValue(execVariable.variable.latex_unit)"></span>
                    <span class="title" v-if="execVariable.title && execVariable.title[current_language]">
                        {{ execVariable.title[current_language] }}
                    </span>
                </div>
                <div class="variable-input-container">
                    <FormulateInput
                        type="text"
                        :value="execVariable.result"
                        :disabled="true"
                        error-behavior="blur"
                    />
                    
                    <Tooltip :text="getHelpText(execVariable.variable)"/>
                </div>
            </div>
        </div>

        <CSError class="variables_error" v-if="calcErrorArray.length" :data-array="calcErrorArray" :title="labels.error" />

        <button
			@click="submitCalculation"
			class="btn btn-primary"
            :disabled="!canSubmitCalculation"
		>
			{{ labels.calculate }}
        </button>
    </BaseCard>
</span>
</template>

<script>
/* eslint no-unused-vars: 0 */
import { mapActions, mapState, mapMutations } from 'vuex';
import inputRuleCheck from '../services/input-rule-check';
import { debounce } from '../services/debounce';
import prepareForm from '../services/prepare-formdata';

import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
Vue.use(VueFormulate);

export default {
    name: 'CSCalculation',
    components: { 
        BaseCard: () => import( './CalculationComponents/BaseCard.vue' ),
        CSTable: () => import( './CSTable.vue' ),
        Tooltip: () => import( './CalculationComponents/Tooltip.vue' ),
        Dropdown: () => import( './CalculationComponents/Dropdown.vue' ),
        CSError: () => import('./CalculationComponents/Error.vue')
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        calculationId: {
            type: String
        }
    },
    data() {
        return {
            labels: {
                no_selectable_value: 'No selectable value',
                input_should_not_be_empty_for_variable: "Input should not be empty for variable",
                select_value_for_variable: "Select value for variable",
                no_selectable_value_for_variable: "No selectable value for variable",
                calculation_has_no_module_chain: "Calculation has no Module Chain",
                calculation_table_check: (varKey, editorId) => `Tabel should be set for variable: "${varKey}", editor id: "${editorId}". This variable is not rendered and calculation is not available until table is set.`,
                calculation_parameter_check: (varKey, editorId) => `Parameter should be set for variable: "${varKey}", editor id: "${editorId}". This variable is not rendered and calculation is not available until parameter is set.`,
                select_value_for_var: (varKey) => `Select value for variable: "${varKey}"`,
                no_module_chains_in_editor: "No Module Chains in Editor",
                no_parameters_exp_var: () => '',
                calculate: 'Calculate',
                select_value: 'Select value',
                to_resolve_this_go_to: (link) => `To fix this error go to ${link}`,
                tab: 'Tab',
                calculation_results: 'Calculation results'
            },
            executionResult: [],
            executionId: false,
            calcError: {},
            canSubmitCalculation: false,
            inputList: []
        }
    },
    mounted() {
        this.getcalculationInterface();
    },
    computed: {
        ...mapState('helper',['current_language'] ),
        ...mapState('calculation', ['calculationInterface', 'singleCalculation']),
        computedCalculationId() {
            if ( this.id ) return this.id
            if ( this.calculationId ) return this.calculationId
            return this.$route?.params?.id || 1;
        },

        calcErrorArray() {
            let res = [];

            if (this.calcError.error) {
                res.push(this.calcError.error);

                let link = this.labels[this.calcError.kind] || this.calcError.kind;

                if (this.calcError.kind && this.calcError.id) {
                    let tmp = `/dashboard/${this.calcError.kind.replace('_', '-')}/${this.calcError.id}?tab=${this.calcError.location}`;
                    link = `<a href="${tmp}">${this.labels[this.calcError.kind]}</a>`;
                }

                let msg = this.labels.to_resolve_this_go_to(link);

                if (this.calcError?.location !== 'global') msg += `, ${this.labels.tab}: "${this.labels[this.calcError.location] || this.calcError.location}"`

                res.push(msg);
            }

            return res;
        }
    },
    methods: {
        ...mapActions('calculation', ['clientRender', 'clientExecute']),
        ...mapMutations('calculation', ['updateInterfaceVariable']),
        ...mapActions('notification',['addNotification']),
        ...mapActions('table', ['getParameterData']),
        getExportId(variable) {
            const result = variable.value?.export_checked && variable.value?.export?.length ? variable.value.export : '';

            return result;
        },
        checkKatexValue(value) {
            if (value && typeof value === 'string') {
                return this.$katex.renderToString(value, {
                    // strict: this.handleError,
                    throwOnError: false,
                });
            }
            return '';
        },
        setInputValue(value, varKey) {
            this.canSubmitCalculation = false;

            debounce(() => {
                this.canSubmitCalculation = true;
                this.updateInterfaceVariable({
                    key: varKey,
                    data: {
                        value
                    }
                });
            }, 500, varKey);
        },
        setTableValue(value, varKey) {
            this.canSubmitCalculation = false;

            debounce(() => {
                this.updateInterfaceVariable({
                    key: varKey,
                    data: {
                        selectedValue: value
                    }
                });

                this.canSubmitCalculation = true;
            }, 500, varKey);
        },
        setValuesDropdown(values, varKey) {
            this.canSubmitCalculation = false;

            let res = [];

            for (let key in values) {
                let value = values[key].value;

                res.push({
                    key,
                    text: values[key].value,
                    action: () => {
                        this.updateInterfaceVariable({
                            key: varKey,
                            data: {
                                selectedType: value,
                                selected: key
                            }
                        });
                    },
                });
            }

            this.canSubmitCalculation = true;

            return res;
        },
        getcalculationInterface() {
            this.clientRender({id: this.computedCalculationId}).then((res) => {
                if (res.success) {
                    for (let key in this.calculationInterface.variables) {
                        if (this.calculationInterface.variables[key].kind == 'table_entry' && !this.calculationInterface.variables[key]?.value?.export) {
                            this.updateInterfaceVariable({
                                key,
                                data: {
                                    selectedType: this.labels.select_value
                                }
                            });
                            this.getParameterDataFN(res.variables, key);
                        }
                    }
                            
                } else if (res.error) {
                    console.log('Errors during calculation load:', res.error);
                }         
            }).catch((err) => console.log(err));
        },
        getParameterDataFN(variables, key) {
            let editorId = key.split('_')[0];
            let variable = key.split('_').slice(1).join('_');
            let data = {
                tableId: variables[key]?.value?.table,
                paramId: variables[key]?.value?.parameter,
                calc: this.computedCalculationId
            };

            if (!data.tableId || !data.paramId) {
                for (let key in data) {
                    let tableMsg = this.labels.calculation_table_check(variable, editorId);
                    let paramMsg = this.labels.calculation_parameter_check(variable, editorId);

                    if (!data[key]) this.addNotification({
                        variant: 'warning',
                        msg: key === 'tableId' ? tableMsg : paramMsg,
                    });
                }
            } else {
                this.getParameterData(data)
                    .then(res => {
                        if (res.success) {
                            let values = {};

                            for (let key in res.data.data) {
                                if (res.data.data[key]?.value) values[key] = res.data.data[key];
                            }

                            this.updateInterfaceVariable({
                                key,
                                data: {
                                    values: values,
                                    dropdownData: this.setValuesDropdown(values, key)
                                }
                            });
                        } else if (res.error) {
                            this.addNotification({
                                variant: 'danger',
                                msg: res.error,
                                labels: this.labels
                            });
                        }
                    })
                    .catch(err => console.log(err));
            }
        },
        validateVariableValue(variable, value, key) {
            let varName = key.split('_').slice(1).join('_');
            let exStr = variable.inputRule[this.current_language];
            let validation = variable.inputRuleText[this.current_language];

            if (this.calculationInterface.variables[key].validation !== validation) {
                this.updateInterfaceVariable({
                    key,
                    data: {
                        validation
                    }
                });
            }

            if (exStr === '' || inputRuleCheck(exStr, value, varName)) {
                return true;
            }

            return false;
        },
        getHelpText(variable) {
            if (variable.helpText && variable.helpText[this.current_language] != undefined) {
                return variable.helpText[this.current_language]
            }

            if (typeof variable.helpText === 'string') return variable.helpText;

            return '';
        },
        submitCalculation() {
            this.loading = true;

            const payload = this.generateCalculationPayload();

            let data = {
                id: this.computedCalculationId,
                moduleChain: this.calculationInterface?.mc_id ? this.calculationInterface.mc_id : '',
                ...payload,
                executionId: this.executionId
            };
            console.log('Errors:', payload.errors);
            if (payload.errors?.length) {
                payload.errors.forEach(msg => {
                    this.addNotification({
                        variant: 'danger',
                        msg
                    });
                });

                return;
            }
            
            this.clientExecute(prepareForm(data)).then(res => {
                if (res.success) {
                    // if (this.calculationInterface && this.calculationInterface.nextModuleChain) {
                    //     this.renderFN(this.calculationInterface.nextModuleChain);
                    // } else this.clearInterfaceVariable();
                    if (Object.keys(res.input).length) this.inputList.push(res.input);
                    if( res.executionId ){
                        this.executionId = res.executionId
                    }
                    if (res.output && Object.keys(res.output).length) this.executionResult.push(res.output);
                } else if (res.msg) {
                    this.addNotification({
                        variant: 'warning',
                        msg: res.msg,
                        labels: this.labels
                    });
                }

                if (res.error) {
                    this.addNotification({
                        variant: 'danger',
                        msg: res.error,
                        labels: this.labels
                    });
                }

                if (res.output?.error) {
                    this.calcError = res.output;
                } else this.calcError = {};

                this.loading = false;
            }).catch(err => {
                console.log(err)
                this.loading = false;
            });
        },
        generateCalculationPayload() {
            let data = {
                payload: {},
                errors: [],
            };
 
            if (this.calculationInterface?.variables) {
                let variables = this.calculationInterface.variables;

                for (let key in variables) {
                    let varKey = key.split('_').slice(1).join('_');
                    let editorId = key.split('_')[0];

                    if (variables[key].kind === 'user_input') {
                        if (!variables[key].value) {
                            data.errors.push(`${this.labels.input_should_not_be_empty_for_variable}: "${varKey}"`);
                        } else {
                            data.payload[key] = variables[key].value;
                        }
                    }

                    if (variables[key].kind === 'table_entry') {
                        if (variables[key]?.dropdownData) {
                            if (variables[key].dropdownData.length) {
                                if (!variables[key].selected) {
                                    data.errors.push(`${this.labels.select_value_for_variable}: "${varKey}"`);
                                } else {
                                    data.payload[key] = variables[key].selected;
                                }
                            } else {
                                data.errors.push(`${this.labels.no_selectable_value_for_variable}: "${varKey}"`);
                            }
                        } else if (variables[key]?.selectedValue) {
                            data.payload[key] = variables[key].selectedValue;
                        } else {
                            data.errors.push(this.labels.calculation_parameter_check(varKey, editorId));
                        }
                    }
                }
            }

            return data;
        }
    }
}
/* eslint no-unused-vars: 1 */
</script>

<style lang="scss" scoped>
    .excecution-title {
        padding-top: 15px;
        border-top: 1px solid rgba(0,0,0,0.3);
        font-weight: 600;
        font-size: 1.1rem;
    }

    .variable {
        margin-bottom: 1rem;

        &-input-container {
            display: flex;
            position: relative;
            padding-right: calc(2rem + 26px);
            width: 100%;

            ::v-deep {

                .formulate-input {
                    width: 100%;
                    .formulate-input-element{
                        max-width: unset;
                    }
                }

                .dropdown {
                    width: 100%;
                }
            }

            .variable-select {
                width: 100%;
            }
        }

        &-label {
            margin-bottom: 1rem;
            display: flex;
            flex-wrap: wrap;

            span {
                display: block;
                margin-right: 0.5rem;
            }
        }
    }

    button:disabled {
        filter: grayscale(30);
        opacity: 0.2;
    }
</style>