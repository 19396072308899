'use strict'
const check = (exec, value, name) =>{

    const isNumber = function(a){
        if(Number(a).toString() === 'NaN') return false;
        else return true
    }
    const finalCheck = function(exec, value, name) {
        exec = exec.replace(/\s/gm, '')
        if (exec.includes('==')) {
            let tmp = exec.split('==');
            if (tmp[0] == name && isNumber(tmp[1])) {
                return Number(value) == Number(tmp[1])
            } else if (tmp[1] == name && isNumber(tmp[0])) {
                return Number(value) == Number(tmp[0])
            } else throw new Error('invalid_rule')
        } else if (exec.includes('>=')) {
            let tmp = exec.split('>=');
            if (tmp[0] == name && isNumber(tmp[1])) {
                return Number(value) >= Number(tmp[1])
            } else if (tmp[1] == name && isNumber(tmp[0])) {
                return Number(value) >= Number(tmp[0])
            } else throw new Error('invalid_rule')
        } else if (exec.includes('<=')) {
            let tmp = exec.split('<=');
            if (tmp[0] == name && isNumber(tmp[1])) {
                return Number(value) <= Number(tmp[1])
            } else if (tmp[1] == name && isNumber(tmp[0])) {
                return Number(value) <= Number(tmp[0])
            } else throw new Error('invalid_rule')
        } else if (exec.includes('<>') || exec.includes('!=')) {
            let tmp = exec.split('<>');
            if (tmp[0] == name && isNumber(tmp[1])) {
                return Number(value) != Number(tmp[1])
            } else if (tmp[1] == name && isNumber(tmp[0])) {
                return Number(value) != Number(tmp[0])
            } else throw new Error('invalid_rule')
        } else if (exec.includes('<')) {
            let tmp = exec.split('<');
            if (tmp[0] == name && isNumber(tmp[1])) {
                return Number(value) < Number(tmp[1])
            } else if (tmp[1] == name && isNumber(tmp[0])) {
                return Number(value) < Number(tmp[0])
            } else throw new Error('invalid_rule')
        } else if (exec.includes('>')) {
            let tmp = exec.split('>');
            if (tmp[0] == name && isNumber(tmp[1])) {
                return Number(value) > Number(tmp[1])
            } else if (tmp[1] == name && isNumber(tmp[0])) {
                return Number(value) > Number(tmp[0])
            } else throw new Error('invalid_rule')
            
        } else throw new Error('invalid_rule')
    }
    const subCheck = function(exec, value, name) {
        if (/(?:OR|or|and|AND)/gm.test(exec)) {
            let subExec = split(exec)
            if (subExec.pieces.length == 1) return check(subExec.pieces[0], value, name)
            else return check(exec, value, name)
        } else {
            return finalCheck(exec, value, name)
        }
    }
    const split = function(exec) {
        let pieces = [];
        let connectors = [];

        for (var i = 0; i < exec.length; i++) {

            if (exec.charAt(i) === ' ') continue;

            if (/^(?:OR|or|and|AND)/gm.test(exec.substring(i, i + 3))) {
                let connector = exec.substring(i).match(/^(?:OR|or|and|AND)/gm)[0]
                connectors.push(connector);

                i = i + connector.length - 1;
            } else if (exec.charAt(i) == '(') {
                let startIndex = i,
                    endIndex = i,
                    brackets = 0;
                for (var j = i; j < exec.length; j++) {
                    if (exec.charAt(j) == '(') brackets++;
                    else if (exec.charAt(j) == ')') {
                        brackets--;
                        if (brackets == 0) {
                            endIndex = j;
                            break;
                        }
                    }
                }
                i = endIndex;
                pieces.push(exec.substring(startIndex + 1, endIndex))
            } else if (!/^(?:OR|or|and|AND)/gm.test(exec.substring(i, i + 3))) {
                let startIndex = i,
                    endIndex = i
                for (var k = i; k < exec.length; k++) {
                    if (exec.charAt(k) === ' ') continue;
                    if (/^(?:OR|or|and|AND)/gm.test(exec.substring(k, k + 3))) break;
                    else endIndex = k;
                }
                i = endIndex;
                pieces.push(exec.substring(startIndex, endIndex + 1))
            }
        }
        return {
            pieces: pieces,
            connectors: connectors
        }
    }

    exec.replace(/\s/gm, '')
    let eSplit = split(exec)
    let pieces = eSplit.pieces
    let matches = eSplit.connectors
    let res;
    try{
        for (var i = 0; i < pieces.length; i++) {
            if (i == 0) res = subCheck(pieces[0], value, name)
            else {
                if (matches[i - 1] == 'and') {
                    res = res && subCheck(pieces[i], value, name)
                } else if (matches[i - 1] == 'or') {
                    res = res || subCheck(pieces[i], value, name)
                }
            }
        }
    }catch(e){
        console.log(e);
        res = false;
    }

    return res;
}

export default check